import React from "react";
import ProjectPreviewGrid from "./project-preview-grid";

const ArchivePage = (props) => {
  const { title, nodes } = props;

  return (
    <main>
      <ProjectPreviewGrid title={title} nodes={nodes} isInArchive />
    </main>
  );
};

export default ArchivePage;
